<template>
  <div>
    <div class="row">
      <div class="col-12 col-md-6">
        <input-select-search
          ref="contaDebitoId"
          v-model="form.contaDebitoId"
          :label="$t(`FINANCEIRO_LANCAMENTOS.SELECIONE_CONTA_DEBITO`)"
          :options="opcoes.contas"
          required
        />
      </div>

      <div class="col-12 col-md-6">
        <input-select-search
          ref="contaCreditoId"
          v-model="form.contaCreditoId"
          :label="$t(`FINANCEIRO_LANCAMENTOS.SELECIONE_CONTA_CREDITO`)"
          :options="opcoes.contas"
          required
        />
      </div>

      <div class="col-12 col-md-6">
        <input-number
          ref="valor"
          v-model="form.valor"
          :label="$t('FINANCEIRO_LANCAMENTOS.VALOR')"
          min="0"
          decimal
          required
        />
      </div>

      <div class="col-12 col-md-6">
        <input-number
          ref="tarifa"
          v-model="form.tarifa"
          :label="$t('FINANCEIRO_LANCAMENTOS.TARIFA')"
          min="0"
          decimal
        />
      </div>

      <div class="col-12 col-md-6">
        <input-date-only-btn
          ref="data"
          v-model="form.data"
          :label="$t('FINANCEIRO_LANCAMENTOS.DATA_DO_MOVIMENTO')"
          required
        />
      </div>

      <div class="col-12 col-md-6">
        <input-text
          ref="observacao"
          v-model="form.observacao"
          :label="$t('FINANCEIRO_LANCAMENTOS.OBSERVACAO')"
        />
      </div>
    </div>
  </div>
</template>
<script>
// Utils & Aux:
import helpers from '@/common/utils/helpers';
import mensagem from '@/common/utils/mensagem';
import { START_LOADING, STOP_LOADING } from '@/store/Store';

// Services:
import ContaService from '@/common/services/conta/conta.service';

//componentes
import {
  InputDateOnlyBtn,
  InputNumber,
  InputText,
  InputSelectSearch,
} from '@/components/inputs';

export default {
  props: {
    form: {
      type: Object,
      default: Object,
    },
  },
  components: {
    InputDateOnlyBtn,
    InputText,
    InputNumber,
    InputSelectSearch,
  },
  data() {
    return {
      opcoes: {
        historicosLancamento: [],
        contas: [],
      },
      filtro: {
        exibir: false,
      },
    };
  },
  mounted() {
    this.getContas();
    this.$emit('refs', this.$refs);
  },
  methods: {
    //  OBTENÇÃO DE DADOS PARA SELECTS
    getContas() {
      this.$store.dispatch(START_LOADING);
      ContaService.obterTodasSemPaginacao()
        .then(({ data }) => {
          this.opcoes.contas = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    // funções modal
    validarFormulario() {
      return helpers.validarFormulario(this.refs);
    },
  },
};
</script>
