<template>
  <b-row>
    <b-col md="3">
      <input-select-search
        ref="contaId"
        v-model="filtro.contaId"
        :label="$t(`FINANCEIRO.CONTAS`)"
        :options="opcoes.contas"
        required
      />
    </b-col>
    <b-col md="3">
      <input-select-search
        ref="historicoId"
        v-model="filtro.historicoLancamentoId"
        :label="$t(`FINANCEIRO.HISTORICO`)"
        :options="opcoes.historicosLancamento"
      />
    </b-col>
    <b-col md="2">
      <input-number
        ref="valor"
        v-model="filtro.valor"
        :label="$t('FINANCEIRO.VALOR')"
        decimal
      />
    </b-col>
    <b-col md="4">
      <input-text
        ref="observacao"
        v-model="filtro.observacao"
        :label="$t(`FINANCEIRO.OBSERVACOES`)"
      />
    </b-col>
    <b-col md="3">
      <input-text
        ref="pagador"
        v-model="filtro.pagador"
        :label="$t(`FINANCEIRO.PAGADOR`)"
      />
    </b-col>
    <b-col md="3">
      <input-date-only-btn
        ref="periodoInicial"
        v-model="filtro.periodoInicial"
        :label="$t('FINANCEIRO.PERIODO_INICIAL')"
      />
    </b-col>
    <b-col md="3">
      <input-date-only-btn
        ref="periodoFinal"
        v-model="filtro.periodoFinal"
        :label="$t('FINANCEIRO.PERIODO_FINAL')"
      />
    </b-col>
    <div class="col-12 col-md-auto align-items-center">
      <b-button
        variant="primary"
        class="botao-acao-filtro mt-3 w-100"
        @click="filtrar"
      >
        {{ $t('GERAL.FILTRAR') }}
      </b-button>
    </div>
    <div class="col-12 col-md-auto">
      <b-button
        variant="secondary"
        class="botao-acao-filtro mt-3 w-100"
        @click="limparFiltro"
      >
        {{ $t('GERAL.LIMPAR') }}
      </b-button>
    </div>
  </b-row>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import helpers from '@/common/utils/helpers';

// Components:
import {
  InputDateOnlyBtn,
  InputSelectSearch,
  InputNumber,
  InputText,
} from '@/components/inputs';

// servicos
import ContaService from '@/common/services/conta/conta.service';
import HistoricoLancamentoService from '@/common/services/historico-lancamento/historico-lancamento.service.js';

export default {
  components: {
    InputDateOnlyBtn,
    InputSelectSearch,
    InputNumber,
    InputText,
  },
  data() {
    return {
      filtro: {},
      opcoes: {
        contas: [],
        historicosLancamento: [],
      },
    };
  },
  watch: {
    'filtro.contaId'(valor) {
      if (valor) {
        this.$emit('desabilitarBotaoProcessarPeriodoFinanceiro', false, valor);
      } else this.$emit('desabilitarBotaoProcessarPeriodoFinanceiro', true, valor);
    },
  },
  mounted() {
    this.getContas();
    this.getHistoricoLancamento();
  },
  methods: {
    validarFiltro() {
      return helpers.validarFormulario(this.$refs);
    },

    limparFiltro() {
      this.filtro = {};
      this.$emit('limpar');
    },
    filtrar() {
      if (!this.validarFiltro()) return;
      this.$emit('filtrar', this.filtro);
    },

    // funções para obter dados
    getContas() {
      this.$store.dispatch(START_LOADING);
      ContaService.obterTodasSemPaginacao()
        .then(({ data }) => {
          this.opcoes.contas = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    getHistoricoLancamento() {
      this.$store.dispatch(START_LOADING);
      HistoricoLancamentoService.obterTodosHistoricosLancamentosSemPaginacao()
        .then(({ data }) => {
          this.opcoes.historicosLancamento = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
  },
};
</script>
