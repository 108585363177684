import ApiService from '@/common/api/api.service'

let apiBasePath = 'PeriodoFinanceiro'

const PeriodoFinanceiroService = {
    // FUNÇÕES CRUD:
    async criar(form) {
        let { data } = await ApiService.post(`${apiBasePath}`, form)
        return data
    },
    async gerarPeriodoFinanceiroPorAnoEConta(form) {
        let { data } = await ApiService.post(`${apiBasePath}/abertura-periodo-financeiro-ano`, form)
        return data
    },
    async editar(form) {
        let { data } = await ApiService.put(`${apiBasePath}`, form)
        return data
    },
    async fechamento(form) {
        let { data } = await ApiService.put(`${apiBasePath}/fechamento`, form)
        return data
    },
    async reabertura(id) {
        let { data } = await ApiService.put(`${apiBasePath}/reabertura?id=${id} `)
        return data
    },
    async abertura(id) {
        let { data } = await ApiService.post(`${apiBasePath}/abertura?id=${id} `)
        return data
    },
    async excluir(itemId) {
        let { data } = await ApiService.delete(`${apiBasePath}/${itemId}`)
        return data
    },
    // FUNÇÕES FILTRO:
    async obterPorFiltro(filtro) {
        let { data } = await ApiService.get(`${apiBasePath}/obter-por-filtro`,
            {
                params: {
                    DataInicio: filtro.dataInicio,
                    DataFim: filtro.dataFim,
                    ContaId: filtro.contaId,
                    Codigo: filtro.codigo,
                    TamanhoDaPagina: filtro.tamanhoDaPagina,
                    NumeroDaPagina: filtro.numeroDaPagina,
                }
            }
        )
        return data
    },
    async obterPorFiltroOrdenacaoDataInicio(filtro) {
        let { data } = await ApiService.get(`${apiBasePath}/obter-por-filtro-data-inicio`,
            {
                params: {
                    DataInicio: filtro.dataInicio,
                    DataFim: filtro.dataFim,
                    ContaId: filtro.contaId,
                    Codigo: filtro.codigo,
                    TamanhoDaPagina: filtro.tamanhoDaPagina,
                    NumeroDaPagina: filtro.numeroDaPagina,
                }
            }
        )
        return data
    }
}

export default PeriodoFinanceiroService