var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-table',{attrs:{"fields":_vm.fields,"items":_vm.items,"empty-text":_vm.$t('GERAL.NENHUM_REGISTRO'),"head-variant":"light","stacked":"md","responsive":"","show-empty":"","striped":"","hover":""},scopedSlots:_vm._u([{key:"cell(valor)",fn:function(item){return [_c('span',{class:{
        'text-danger': item.item.tipoLancamento === 'Debito',
        'text-success': item.item.tipoLancamento === 'Credito',
      }},[_vm._v(" "+_vm._s(item.item.valor)+" ")])]}},{key:"cell(saldo)",fn:function(item){return [_c('span',{class:{
        'text-danger': item.item.saldoNaoModificado < 0,
        'text-success': item.item.saldoNaoModificado >= 0,
      }},[_vm._v(" "+_vm._s(item.item.saldo)+" ")])]}},{key:"cell(acoes)",fn:function(item){return [(
        item.item.tipoLancamento === 'Credito' ||
        item.item.tipoLancamento === 'Debito'
      )?_c('b-dropdown',{attrs:{"variant":"outline-default"}},[_c('b-dropdown-item',{on:{"click":function($event){return _vm.gerarReciboLancamento(item.item)}}},[_vm._v(" "+_vm._s(_vm.$t('FINANCEIRO.GERAR_RECIBO_PAGAMENTO'))+" ")]),_c('b-dropdown-item',{on:{"click":function($event){return _vm.excluir(item.item)}}},[_vm._v(" "+_vm._s(_vm.$t('FINANCEIRO.EXCLUIR_LANCAMENTO'))+" ")])],1):_vm._e()]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }