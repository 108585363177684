<template>
  <topbar
    :titulo="$t('FINANCEIRO.FLUXO_CAIXA')"
    :subtitulo="$t('FINANCEIRO.FLUXO_CAIXA_DESCRICAO')"
    :breadcrumbs="breadcrumbs"
  >
    <template #header>
      <div class="d-flex justify-content-between ml-md-4">
        <b-button
          :disabled="desabilitaBotaoProcessarPeriodoFinanceiro"
          class="mr-md-3"
          variant="info"
          @click="abrirModalProcessarPeriodoFinanceiro()"
        >
          {{ $t('FINANCEIRO.PROCESSAR_PERIODO_FINANCEIRO') }}
        </b-button>
        <b-button class="mr-md-3" variant="info" @click="transferir()">
          {{ $t('FINANCEIRO.TRANSFERENCIA') }}
        </b-button>
        <b-button variant="info" @click="cadastrar()">
          {{ $t('GERAL.CADASTRAR') }}
        </b-button>
      </div>
    </template>
  </topbar>
</template>
<script>
// Utils & Aux:
import Topbar from '@/layouts/full-layout/horizontal-sidebar/Topbar.vue';

export default {
  components: { Topbar },
  props: {
    desabilitaBotaoProcessarPeriodoFinanceiro: { type: Boolean, default: true },
  },
  data() {
    return {
      breadcrumbs: [
        { titulo: this.$t('FINANCEIRO.FINANCEIRO') },
        { titulo: this.$t('FINANCEIRO.CAIXA') },
        { titulo: this.$t('FINANCEIRO.FLUXO_CAIXA') },
      ],
    };
  },
  methods: {
    abrirModalProcessarPeriodoFinanceiro() {
      this.$emit('abrirModalProcessarPeriodoFinanceiro');
    },
    cadastrar() {
      this.$emit('abrirModalFluxoCaixa');
    },
    transferir() {
      this.$emit('abrirModalTransferencia');
    },
  },
};
</script>
