<template>
  <modal
    id="modalTranferenciaCaixa"
    :exibir="exibir"
    :titulo="titulo"
    :campos="$refs"
    tamanho="md"
    @fechar="fecharModal"
  >
    <dados :form="formulario" @refs="capturarRefs" />

    <template #modal-footer>
      <div class="d-flex justify-content-between w-100">
        <b-button variant="secondary" @click="fecharModal">
          {{ $t('GERAL.FECHAR') }}
        </b-button>
        <b-button variant="primary" @click="salvar">
          {{ $t('GERAL.SALVAR') }}
        </b-button>
      </div>
    </template>
  </modal>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import helpers from '@/common/utils/helpers';

// Services:
import LancamentoCaixaService from '@/common/services/fluxo-caixa/fluxo-caixa.service';

// Components:
import Modal from '@/components/modal/Modal';
import Dados from './Dados.vue';

export default {
  props: {
    exibir: { type: Boolean, default: false },
  },
  components: {
    Modal,
    Dados,
  },
  data() {
    return {
      refs: [],
      formulario: {
        valor: 0,
        tarifa: 0,
        observacao: '',
        data: '',
        contaDebitoId: '',
        contaCreditoId: '',
      },
    };
  },
  computed: {
    titulo() {
      return this.$t('FINANCEIRO_LANCAMENTOS.TRANSFERENCIA_ENTRE_CONTAS');
    },
  },
  watch: {
    'formulario.valorDocumento': 'calcularTotal',
    'formulario.juros': 'calcularTotal',
    'formulario.desconto': 'calcularTotal',
  },
  methods: {
    // FUNÇÕES CRUD:
    salvar() {
      if (!this.validarFormulario()) return;
      this.$store.dispatch(START_LOADING);
      LancamentoCaixaService.realizarTransferenciaEntreContas(this.formulario)
        .then((data) => {
          mensagem.sucesso(this.$t('GERAL.SUCESSO'), data.mensagem);
          this.formulario = {
            valor: 0,
            tarifa: 0,
            observacao: '',
            data: '',
            contaDebitoId: '',
            contaCreditoId: '',
          };
          this.fecharModal();
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
          this.atualizarTabela();
        });
    },

    // FUNÇÕES DE VALIDAÇÃO FORMULÁRIO:
    capturarRefs(refs) {
      this.refs = refs;
    },
    validarFormulario() {
      return helpers.validarFormulario(this.refs);
    },
    // FUNÇÕES P/ COMPONENTE PAI:
    // FUNÇÕES MODAL:
    fecharModal() {
      this.$emit('fechar', 'periodoFinanceiro');
    },
    atualizarTabela() {
      this.$emit('atualiza-tabela');
    },
  },
};
</script>
