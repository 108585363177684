/*
    Helpers de data e horas

    Aqui estão todas as funções que auxiliam nas datas, favor não tumultuar :)
*/

import moment from "moment";

const obterMesTextoAno = data => {
    if (!data) return;

    const meses = [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro'
    ];

    let tempData = new Date(data);

    let mesNome = meses[tempData.getMonth()];
    let ano = tempData.getFullYear();

    return `${mesNome.toLowerCase()}/${ano}`;
};

// formatar datas para mostrar

const formatDateToShow = date => {
    return moment(date).format('DD/MM/YYYY');
};

export default {
    obterMesTextoAno,
    formatDateToShow
};
