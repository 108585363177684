import ApiService from '@/common/api/api.service';

let apiBasePath = 'LancamentoCaixa';
const FluxoCaixaService = {
    // FUNÇÕES DE BUSCA:
    async listarPorFiltroPaginado(filtro, paginacao) {
        for (let chave in filtro) {
            if (filtro[chave] == undefined || filtro[chave] == '') {
                delete filtro[chave];
            }
        }

        let { data } = await ApiService.get(
            `${apiBasePath}/obter-todos-por-filtro`,
            {
                params: {
                    ...paginacao,
                    ...filtro
                }
            }
        );
        return data;
    },
    async excluir(id) {
        let { data } = await ApiService.delete(`${apiBasePath}/${id}`);
        return data;
    },
    async imprimirRecibo(form) {
        let { data } = await ApiService.post(
            `${apiBasePath}/imprimir-recibo`,
            form
        );
        return data;
    },
    async imprimirExtrato(form) {
        let { data } = await ApiService.get(
            `${apiBasePath}/relatorio-fluxo-de-caixa`, {
            params: {
                ...form
            }
        }

        );
        return data;
    },
    async realizarTransferenciaEntreContas(form) {
        let { data } = await ApiService.put(
            `${apiBasePath}/transferencia`,
            form
        );
        return data;
    },
    async criar(form) {
        let { data } = await ApiService.post(`${apiBasePath}`, form);
        return data;
    },
    async editar(form) {
        let { data } = await ApiService.put(`${apiBasePath}`, form);
        return data;
    },
    async obterLancamentosPorPeriodoPaginado(filtro, paginacao){
        let { data } = await ApiService.get(`${apiBasePath}/obter-lancamentos-por-periodo-paginado`, {
            params: {
                ...filtro,
                ...paginacao
            }
        });
        return data;
    }, 
    // Relatorios
    async gerarImpostoDeRendaPdf(filtro) {
        let { data } = await ApiService.get(
          `${apiBasePath}/relatorio-declaracao-imposto-renda`,
          {
            params: {
              ...filtro,
            },
          }
        );
        return data;
    },
    async gerarRelatorioFaturamentoPdf(filtro){
        let { data } = await ApiService.get(`${apiBasePath}/gerar-relatorio-faturamento/`, {
            params: {
                ...filtro
            }
        });
        return data;
    }, 
    async gerarRelatorioLancamentoPorPeriodo(filtro){
        let { data } = await ApiService.get(`${apiBasePath}/relatorio-lancamentos-por-periodo`, {
            params: {
                ...filtro,
            }
        });
        return data;
    }, 
   
};

export default FluxoCaixaService;
