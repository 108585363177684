<template>
  <div>
    <div class="row">
      <div class="col-12 col-md-4">
        <input-select-search
          ref="tipoLancamento"
          v-model="form.tipoLancamento"
          :label="$t(`FINANCEIRO_LANCAMENTOS.TIPO_LANCAMENTO`)"
          :options="opcoes.tiposLancamento"
          required
        />
      </div>
      <div class="col-12 col-md-6">
        <input-select-search
          ref="historicoLancamentoId"
          v-model="form.historicoLancamentoId"
          :label="$t(`FINANCEIRO_LANCAMENTOS.HISTORICO_LANCAMENTOS`)"
          :options="opcoes.historicosLancamento"
          required
        />
      </div>
      <div class="col-12 col-md-4">
        <input-date-only-btn
          ref="dataLancamento"
          v-model="form.dataLancamento"
          :label="$t('FINANCEIRO_LANCAMENTOS.DATA_LANCAMENTO')"
          required
        />
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-md-6">
        <input-select-search
          ref="contaId"
          v-model="form.contaId"
          :label="$t(`FINANCEIRO_LANCAMENTOS.CONTA`)"
          :options="opcoes.contas"
          required
        />
      </div>
      <div class="col-12 col-md-3">
        <input-number
          ref="valorDocumento"
          v-model="form.valorDocumento"
          :label="$t('FINANCEIRO_LANCAMENTOS.VALOR_DO_DOCUMENTO')"
          min="0"
          decimal
          required
        />
      </div>
      <div class="col-12 col-md-3">
        <input-number
          ref="juros"
          v-model="form.juros"
          :label="$t('FINANCEIRO_LANCAMENTOS.JUROS')"
          min="0"
          decimal
        />
      </div>

      <div class="col-12 col-md-3">
        <input-number
          ref="desconto"
          v-model="form.desconto"
          :label="$t('FINANCEIRO_LANCAMENTOS.DESCONTO')"
          min="0"
          decimal
        />
      </div>
      <div class="col-12 col-md-3">
        <input-number
          ref="valorTotal"
          v-model="form.valorTotal"
          :label="$t('FINANCEIRO_LANCAMENTOS.VALOR_TOTAL')"
          min="0"
          decimal
          required
          disabled
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-4">
        <input-text
          ref="pagador"
          v-model="form.pagador"
          :label="$t('FINANCEIRO_LANCAMENTOS.PAGADOR')"
        />
      </div>
      <div class="col-12 col-md-4">
        <input-text
          ref="referencia"
          v-model="form.referencia"
          :label="$t('FINANCEIRO_LANCAMENTOS.REFERENCIA')"
        />
      </div>
      <div class="col-12 col-md-4">
        <input-text
          ref="observacao"
          v-model="form.observacao"
          :label="$t('FINANCEIRO_LANCAMENTOS.OBSERVACAO')"
        />
      </div>
    </div>
  </div>
</template>
<script>
// Utils & Aux:
import helpers from '@/common/utils/helpers';
import mensagem from '@/common/utils/mensagem';
import { START_LOADING, STOP_LOADING } from '@/store/Store';

// Services:
import ContaService from '@/common/services/conta/conta.service';
import EnumeradoresService from '@/common/services/enumeradores/enumeradores.service';
import HistoricoLancamentoService from '@/common/services/historico-lancamento/historico-lancamento.service.js';

//componentes
import {
  InputDateOnlyBtn,
  InputNumber,
  InputText,
  InputSelectSearch,
} from '@/components/inputs';

export default {
  props: {
    form: {
      type: Object,
      default: Object,
    },
  },
  components: {
    InputDateOnlyBtn,
    InputText,
    InputNumber,
    InputSelectSearch,
  },
  data() {
    return {
      opcoes: {
        tiposLancamento: [],
        historicosLancamento: [],
        contas: [],
      },
      filtro: {
        exibir: false,
      },
    };
  },
  mounted() {
    this.getTipoLancamento();
    this.getHistoricoLancamento();
    this.getContas();
    this.$emit('refs', this.$refs);
  },
  methods: {
    //  OBTENÇÃO DE DADOS PARA SELECTS
    getTipoLancamento() {
      this.$store.dispatch(START_LOADING);
      EnumeradoresService.listar('tipo-lancamento')
        .then(({ data }) => {
          data = data.map((row) => ({
            value: row.id,
            text: row.descricao,
          }));
          this.opcoes.tiposLancamento = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    getHistoricoLancamento() {
      this.$store.dispatch(START_LOADING);
      HistoricoLancamentoService.obterTodosHistoricosLancamentosSemPaginacao()
        .then(({ data }) => {
          this.opcoes.historicosLancamento = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    getContas() {
      this.$store.dispatch(START_LOADING);
      ContaService.obterTodasSemPaginacao()
        .then(({ data }) => {
          this.opcoes.contas = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    // funções modal
    abrirFecharForm() {
      this.filtro.exibir == false
        ? (this.filtro.exibir = true)
        : (this.filtro.exibir = false);
    },

    validarFormulario() {
      return helpers.validarFormulario(this.refs);
    },
  },
};
</script>
