import ApiService from '@/common/api/api.service';

let apiBasePath = 'HistoricoLancamento';

const HistoricoLancamentoService = {
    async criar(form) {
        if (form.id) {
            let data = await ApiService.put(`${apiBasePath}`, form);
            return data;
        } else {
            let data = await ApiService.post(`${apiBasePath}`, form);
            return data;
        }
    },
    async excluir(itemId) {
        let { data } = await ApiService.delete(`${apiBasePath}/${itemId}`);
        return data;
    },
    async obtemHistoricoLancamentoPorId(itemId) {
        let { data } = await ApiService.get(`${apiBasePath}/${itemId}`);
        return data;
    },
    async obterTodosHistoricosLancamentosSemPaginacao() {
        let { data } = await ApiService.get(`${apiBasePath}/sem-paginacao`);
        data.data = data.data.map((row) => ({
            text: row.descricao,
            value: row.id,
        }));
        return data
    },
    async obterEstoque() {
        let { data } = await ApiService.get(`${apiBasePath}/estoques`);
        return data;
    },
    async obterEnumPor(item) {
        let { data } = await ApiService.get(`${apiBasePath}/por-parametro`, {
            params: {
                tipoLancamento: item
            }
        });
        return data;
    },
    async obterEnumPaginadoPor(item, paginacao) {
        let { data } = await ApiService.get(`${apiBasePath}/por-parametro-paginado`, {
            params: {
                tipoLancamento: item,
                TamanhoDaPagina: paginacao.tamanhoDaPagina,
                NumeroDaPagina: paginacao.numeroDaPagina
            }
        });
        return data;
    },
    async obterEnumEstoque() {
        let { data } = await ApiService.get(`${apiBasePath}/estoque`);
        return data;
    },
    async obterEnumContasPagar() {
        let { data } = await ApiService.get(`${apiBasePath}/contas-a-pagar`);
        data.data = data.data.map((row) => ({
            text: row.descricao,
            value: row.id,
        }));
        return data
    },
    async obterHistoricoLancamentoPorTipo(tipoHistorico) {
        let { data } = await ApiService.get(`${apiBasePath}/obter-historico-por-tipo`, { params: { tipoHistorico: tipoHistorico } });
        return data;
    },
    async obterEnumContasPagarPaginado(paginacao) {
        let { data } = await ApiService.get(
            `${apiBasePath}/historicos-paginados/contas-pagar`,
            {
                params: {
                    TamanhoDaPagina: paginacao.tamanhoDaPagina,
                    NumeroDaPagina: paginacao.numeroDaPagina
                }
            }
        );
        return data;
    },
    async obterEnumContasReceber(paginacao) {
        let { data } = await ApiService.get(
            `${apiBasePath}/historicos-paginados/contas-a-receber`,
            {
                params: {
                    TamanhoDaPagina: paginacao.tamanhoDaPagina,
                    NumeroDaPagina: paginacao.numeroDaPagina
                }
            }
        );
        return data;
    },
    async obterEnumContasBancoCaixa() {
        let { data } = await ApiService.get(
            `${apiBasePath}/contas-banco-ou-caixa`
        );
        return data;
    }
};

export default HistoricoLancamentoService;
