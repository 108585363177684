<template>
  <modal
    id="modalAberturaFechamentoPeriodo"
    :exibir="exibir"
    :titulo="titulo"
    :campos="$refs"
    tamanho="xl"
    @fechar="fecharModal"
  >
    <div class="d-flex justify-content-end w-100 mb-2">
      <b-button variant="primary" @click="aberturaPeriodo()">
        {{ $t('FINANCEIRO.PROCESSAR_ABERTURA_PERIODO') }}
      </b-button>
    </div>
    <b-table
      :fields="fields"
      :items="itens"
      :empty-text="$t('GERAL.NENHUM_REGISTRO')"
      head-variant="light"
      stacked="md"
      responsive
      show-empty
      striped
      hover
    >
      <template #cell(acoes)="item">
        <b-dropdown variant="outline-default">
          <b-dropdown-item
            v-if="item.item.statusFinanceiroDescricao !== 'Fechado'"
            @click="fechamentoPeriodo(item.item)"
          >
            {{ $t('FINANCEIRO.PROCESSAR_FECHAMENTO_PERIODO') }}
          </b-dropdown-item>
          <b-dropdown-item v-else @click="reaberturaPeriodo(item.item)">
            {{ $t('FINANCEIRO.PROCESSAR_REABERTURA_PERIODO') }}
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>

    <paginacao
      v-model="paginacao"
      :total="paginacao.total"
      @input="buscaPeriodoFinanceiro('')"
    />

    <template #modal-footer>
      <div class="d-flex justify-content-between w-100">
        <b-button variant="secondary" @click="fecharModal">
          {{ $t('GERAL.FECHAR') }}
        </b-button>
        <!-- <b-button variant="primary" @click="salvar">
            {{ $t('GERAL.SALVAR') }}
          </b-button> -->
      </div>
    </template>
  </modal>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import helpers from '@/common/utils/helpers';

//   // Services:
import PeriodoFinanceiroService from '@/common/services/periodo-financeiro/periodo-financeiro.service.js';

// Components:
import Modal from '@/components/modal/Modal';
import Paginacao from '@/components/paginacao/Paginacao';

export default {
  props: {
    exibir: { type: Boolean, default: false },
  },
  components: {
    Modal,
    Paginacao,
  },
  data() {
    return {
      itens: [],
      paginacao: {
        numeroDaPagina: 1,
        tamanhoDaPagina: 10,
        total: 0,
      },
      fields: [
        {
          key: 'dataInicio',
          label: 'Data Início',
          formatter: (value) => {
            return value ? helpers.formatarDataBr(value) : '-';
          },
        },
        {
          key: 'dataFim',
          label: 'Data Fim',
          formatter: (value) => {
            return value ? helpers.formatarDataBr(value) : '-';
          },
        },
        {
          key: 'statusFinanceiroDescricao',
          label: 'Status',
        },
        {
          key: 'saldoAbertura',
          label: 'Saldo Inicial',
          formatter: (value) => {
            return value ? helpers.formatarReal(value) : '-';
          },
        },
        {
          key: 'saldoFechamento',
          label: 'Saldo Final',
          formatter: (value) => {
            return value ? helpers.formatarReal(value) : '-';
          },
        },
        {
          key: 'acoes',
          label: 'Ações',
        },
      ],
    };
  },
  computed: {
    titulo() {
      return this.$t('FINANCEIRO.ABERTURA_FECHAMENTO_PERIODO_FINANCEIRO');
    },
  },
  watch: {},
  methods: {
    fechamentoPeriodo(item) {
      this.$store.dispatch(START_LOADING);
      PeriodoFinanceiroService.fechamento({
        periodoId: item.id,
        contaId: item.contaId,
      })
        .then(() => {
          this.buscaPeriodoFinanceiro(this.contaId);
          mensagem.sucesso(
            this.$t('GERAL.SUCESSO'),
            this.$t('FINANCEIRO.PERIODO_FINANCEIRO_FECHADO')
          );
        })
        .catch((err) => {
          mensagem.warning(
            this.$t('GERAL.AVISO'),
            err.response.data.errors[0].mensagem
          );
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    reaberturaPeriodo(item) {
      this.$store.dispatch(START_LOADING);
      PeriodoFinanceiroService.reabertura(item.id)
        .then(() => {
          this.buscaPeriodoFinanceiro(this.contaId);
          mensagem.sucesso(
            this.$t('GERAL.SUCESSO'),
            this.$t('FINANCEIRO.PERIODO_FINANCEIRO_REABERTURA')
          );
        })
        .catch((err) => {
          mensagem.warning(this.$t('GERAL.AVISO'), err.response.data.errors);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    aberturaPeriodo() {
      this.$store.dispatch(START_LOADING);
      PeriodoFinanceiroService.abertura(this.contaId)
        .then(() => {
          this.buscaPeriodoFinanceiro(this.contaId);
          mensagem.sucesso(
            this.$t('GERAL.SUCESSO'),
            this.$t('FINANCEIRO.PERIODO_FINANCEIRO_REABERTURA')
          );
        })
        .catch((err) => {
          mensagem.warning(
            this.$t('GERAL.AVISO'),
            err.response.data.errors[0].mensagem
          );
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    fecharModal() {
      this.$emit('fechar');
    },
    buscaPeriodoFinanceiro(contaId) {
      this.contaId = contaId ? contaId : null;
      let filtroFormatado = {
        contaId: this.contaId,
      };
      this.$store.dispatch(START_LOADING);
      PeriodoFinanceiroService.obterPorFiltroOrdenacaoDataInicio({
        ...filtroFormatado,
        ...this.paginacao,
      })
        .then(({ data }) => {
          this.itens = data.itens;
          this.paginacao = data.paginacao;
          this.paginacao.total = data.paginacao.totalDeElementos;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
  },
};
</script>
<style>
/* .modal-xl {
  min-width: 895px !important;
} */
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px !important;
  }
}
</style>
