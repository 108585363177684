<template>
  <b-table
    :fields="fields"
    :items="items"
    :empty-text="$t('GERAL.NENHUM_REGISTRO')"
    head-variant="light"
    stacked="md"
    responsive
    show-empty
    striped
    hover
  >
    <template #cell(valor)="item">
      <span
        :class="{
          'text-danger': item.item.tipoLancamento === 'Debito',
          'text-success': item.item.tipoLancamento === 'Credito',
        }"
      >
        {{ item.item.valor }}
      </span>
    </template>
    <template #cell(saldo)="item">
      <span
        :class="{
          'text-danger': item.item.saldoNaoModificado < 0,
          'text-success': item.item.saldoNaoModificado >= 0,
        }"
      >
        {{ item.item.saldo }}
      </span>
    </template>
    <template #cell(acoes)="item">
      <b-dropdown
        variant="outline-default"
        v-if="
          item.item.tipoLancamento === 'Credito' ||
          item.item.tipoLancamento === 'Debito'
        "
      >
        <b-dropdown-item @click="gerarReciboLancamento(item.item)">
          {{ $t('FINANCEIRO.GERAR_RECIBO_PAGAMENTO') }}
        </b-dropdown-item>
        <b-dropdown-item @click="excluir(item.item)">
          {{ $t('FINANCEIRO.EXCLUIR_LANCAMENTO') }}
        </b-dropdown-item>
      </b-dropdown>
    </template>
  </b-table>
</template>
<script>
export default {
  props: {
    items: { type: Array, default: Array },
  },
  data() {
    return {
      fields: [
        {
          key: 'dataLancamento',
          label: 'Data',
        },
        {
          key: 'identificacao',
          label: 'Identificação',
          sortable: true,
        },
        {
          key: 'historicoLancamento',
          label: 'Histórico',
          sortable: true,
        },
        {
          key: 'codigoDocumento',
          label: 'Documento',
          sortable: true,
        },
        {
          key: 'codigoDocumentoReferencia',
          label: 'Documento de Referência',
          sortable: true,
        },
        {
          key: 'valor',
          label: 'Valor',
          sortable: true,
        },
        {
          key: 'saldo',
          label: 'Saldo',
          sortable: true,
        },
        {
          key: 'numeroRecibo',
          label: 'Recibo',
          sortable: true,
        },
        {
          key: 'pagador',
          label: 'Pagador',
          sortable: true,
        },
        {
          key: 'referencia',
          label: 'Referência',
          sortable: true,
        },
        {
          key: 'observacao',
          label: 'Observações',
          sortable: true,
        },
        {
          key: 'acoes',
          label: 'Ações',
        },
      ],
    };
  },
  methods: {
    gerarReciboLancamento(item) {
      this.$emit('gerar-recibo-lancamento', item);
    },
    excluir(item) {
      this.$emit('excluir-lancamento', item.id);
    },
    gerarExtrato() {
      this.$emit('gerar-extrato');
    },
  },
};
</script>
