<template>
  <div>
    <titulo-fluxo-caixa
      :desabilitaBotaoProcessarPeriodoFinanceiro="
        desabilitaBotaoProcessarPeriodoFinanceiro
      "
      @abrirModalFluxoCaixa="abrirModalFluxoCaixa"
      @abrirModalTransferencia="abrirModalTransferencia"
      @abrirModalProcessarPeriodoFinanceiro="
        abrirModalAberturaFechamentoPeriodoFinanceiro
      "
    />
    <filtro-fluxo
      @filtrar="obterFiltro"
      @limpar="limparFiltro"
      @desabilitarBotaoProcessarPeriodoFinanceiro="
        desabilitarBotaoProcessarPeriodoFinanceiro
      "
    />

    <b-button
      variant="primary"
      @click="gerarExtrato"
      :disabled="gerarExtratoBtn"
      class="mb-3"
    >
      {{ $t('FINANCEIRO.GERAR_EXTRATO') }}
    </b-button>

    <Tabela-fluxo
      :items="itens"
      @excluir-lancamento="excluirLancamento"
      @gerar-recibo-lancamento="gerarReciboLancamento"
      @abreModalAberturaFechamentoPeriodo="
        abrirModalAberturaFechamentoPeriodoFinanceiro
      "
    />
    <paginacao
      v-model="paginacao"
      :total="paginacao.total"
      @input="obterFiltro"
    />
    <modal-fluxo-caixa
      :exibir="modais.fluxoCaixa"
      :form="modais.fluxoCaixaDados"
      @atualiza-tabela="obterFiltro"
      @fechar="fecharModalFluxoCaixa"
    />
    <modal-transferencia
      :exibir="modais.transferencia"
      @fechar="fecharModalTransferencia"
      @atualiza-tabela="obterFiltro"
    />
    <ModalAberturaFechamentoPeriodoFinanceiro
      ref="refModalAberturaFechamentoPeriodoFinanceiro"
      :exibir="modais.aberturaFechamentoPeriodo"
      @fechar="fecharModalAberturaFechamentoPeriodoFinanceiro"
    />
  </div>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import pdf from '@/common/utils/pdf';
import datas from '@/common/utils/datas';

// Components:
import TituloFluxoCaixa from './components/fluxo/Titulo';
import ModalFluxoCaixa from './components/fluxo/modal/Index.vue';
import ModalTransferencia from './components/fluxo/modal-transferencia/Index.vue';
import ModalAberturaFechamentoPeriodoFinanceiro from './components/fluxo/modal-abertura-fechamento-periodo/ModalAberturaFechamentoPeriodoFinanceiro.vue';
import FiltroFluxo from './components/fluxo/Filtro';
import TabelaFluxo from './components/fluxo/Tabela';
import Paginacao from '@/components/paginacao/Paginacao';

// services
import FluxoCaixaService from '@/common/services/fluxo-caixa/fluxo-caixa.service';

export default {
  components: {
    TituloFluxoCaixa,
    FiltroFluxo,
    TabelaFluxo,
    Paginacao,
    ModalFluxoCaixa,
    ModalTransferencia,
    ModalAberturaFechamentoPeriodoFinanceiro,
  },
  data() {
    return {
      itens: [],
      gerarExtratoBtn: true,
      desabilitaBotaoProcessarPeriodoFinanceiro: true,
      paginacao: {
        numeroDaPagina: 1,
        tamanhoDaPagina: 10,
        total: 0,
      },
      filtro: {
        contaId: null,
      },
      modais: {
        fluxoCaixa: false,
        fluxoCaixaDados: {
          tipoLancamento: '',
          dataLancamento: '',
          observacao: '',
          valorDocumento: 0,
          juros: 0,
          desconto: 0,
          valorTotal: 0,
          pagador: '',
          referencia: '',
          historicoLancamentoId: '',
          tipoConta: '',
          contaId: '',
        },
        transferencia: false,
        aberturaFechamentoPeriodo: false,
      },
    };
  },
  methods: {
    desabilitarBotaoProcessarPeriodoFinanceiro(value, contaId) {
      this.desabilitaBotaoProcessarPeriodoFinanceiro = value;
      this.filtro.contaId = contaId;
    },
    fecharModalAberturaFechamentoPeriodoFinanceiro() {
      this.modais.aberturaFechamentoPeriodo = false;
    },
    abrirModalAberturaFechamentoPeriodoFinanceiro() {
      this.$refs[
        'refModalAberturaFechamentoPeriodoFinanceiro'
      ].buscaPeriodoFinanceiro(this.filtro.contaId);
      this.modais.aberturaFechamentoPeriodo = true;
    },
    obterFiltro(filtro) {
      this.gerarExtratoBtn = false;

      if (filtro?.contaId) {
        this.filtro = filtro;
      }
      if (!this.filtro?.contaId) return;

      this.$store.dispatch(START_LOADING);
      FluxoCaixaService.listarPorFiltroPaginado(this.filtro, this.paginacao)
        .then(({ data }) => {
          if (data.itens.length === 0) return (this.itens = []);
          const formattedItens = data.itens.map((item) => {
            return {
              ...item,
              dataLancamento: datas.formatDateToShow(item.dataLancamento),
              valor: this.formatValueByType(item.valor, item.tipoLancamento),
              saldo: this.formatValueByNotType(item.saldo),
              saldoNaoModificado: item.saldo,
            };
          });

          const saldoAnterior = this.formatValueByType(
            data.itens[0]?.saldoAnterior,
            data.itens[0]?.tipoLancamento
          );

          const saldoAtualizado = this.formatValueByNotType(
            data.itens[data.itens.length - 1]?.saldo
          );

          this.itens = [
            {
              historicoLancamento: 'Saldo anterior',
              saldo: saldoAnterior,
              _rowVariant: 'warning',
            },
            ...formattedItens,
            {
              historicoLancamento: 'Saldo atualizado',
              saldo: saldoAtualizado,
              _rowVariant: 'warning',
            },
          ];

          this.paginacao.total = data.paginacao.totalDeElementos;
        })
        .catch((err) => {
          this.itens = [];
          (this.paginacao = {
            numeroDaPagina: 1,
            tamanhoDaPagina: 10,
            total: 0,
          }),
            mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    limparFiltro() {
      this.filtro = {};
      this.itens = [];
      this.paginacao = {
        numeroDaPagina: 1,
        tamanhoDaPagina: 10,
        total: 0,
      };
      this.gerarExtratoBtn = true;
    },

    // FUNÇÕES TABELA:
    gerarExtrato() {
      this.$store.dispatch(START_LOADING);
      FluxoCaixaService.imprimirExtrato(this.filtro)
        .then(({ data }) => {
          if (data.documento) {
            let bytes = pdf.base64ToArrayBuffer(data.documento);
            let blob = new Blob([bytes], {
              type: 'application/pdf',
            });

            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = data.nomeArquivo;
            link.click();
          }
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    gerarReciboLancamento(item) {
      this.$store.dispatch(START_LOADING);
      FluxoCaixaService.imprimirRecibo({
        lancamentoCaixaId: item.id,
        tipoDocumento: 'LC',
      })
        .then(({ data }) => {
          if (data.documento) {
            //converte os bytes em array:
            let bytes = pdf.base64ToArrayBuffer(data.documento);
            // converte o array em blob
            let blob = new Blob([bytes], {
              type: 'application/pdf',
            });

            // faz download do PDF:
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = data.nomeArquivo;
            link.click();
          }
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    editarLancamento(item) {
      this.modais.fluxoCaixaDados = item;
      this.abrirModalFluxoCaixa();
    },

    excluirLancamento(id) {
      this.$store.dispatch(START_LOADING);
      FluxoCaixaService.excluir(id)
        .then((data) => {
          this.obterFiltro();
          mensagem.sucesso(
            this.$t('GERAL.SUCESSO'),
            this.$t(data.data.mensagem)
          );
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    // FUNÇÕES MODAL
    // fluxo caixa
    abrirModalFluxoCaixa() {
      this.modais.fluxoCaixa = true;
    },

    fecharModalFluxoCaixa() {
      this.modais.fluxoCaixa = false;
    },
    // transferencia
    abrirModalTransferencia() {
      this.modais.transferencia = true;
    },

    fecharModalTransferencia() {
      this.modais.transferencia = false;
    },

    // FUNÇOES AUXILIARES
    formatValueByType(valor, tipo) {
      const formattedValue = Math.abs(valor).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      });
      return formattedValue + (tipo === 'Debito' ? ' D' : ' C');
    },

    formatValueByNotType(valor) {
      // Verifica se o valor é negativo
      const tipo = valor < 0 ? 'D' : 'C';
      const formattedValue = Math.abs(valor).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      });

      return formattedValue + ' ' + tipo;
    },
  },
};
</script>
