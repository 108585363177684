<template>
  <modal
    id="modalPeriodoFinanceiro"
    :exibir="exibir"
    :titulo="titulo"
    :campos="$refs"
    tamanho="xl"
    @fechar="fecharModal"
  >
    <dados
      :form="formulario"
      @refs="capturarRefs"
    />

    <template #modal-footer>
      <div class="d-flex justify-content-between w-100">
        <b-button variant="secondary" @click="fecharModal">
          {{ $t('GERAL.FECHAR') }}
        </b-button>
        <b-button variant="primary" @click="salvar">
          {{ $t('GERAL.SALVAR') }}
        </b-button>
      </div>
    </template>
  </modal>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import helpers from '@/common/utils/helpers';
import pdf from '@/common/utils/pdf';

// Services:
import LancamentoCaixaService from '@/common/services/fluxo-caixa/fluxo-caixa.service';
import FluxoCaixaService from '@/common/services/fluxo-caixa/fluxo-caixa.service';

// Components:
import Modal from '@/components/modal/Modal';
import Dados from './Dados';

export default {
  props: {
    exibir: { type: Boolean, default: false },
    form: {
      type: Object,
      default: () => ({
        tipoLancamento: '',
        dataLancamento: '',
        observacao: '',
        valorDocumento: 0,
        juros: 0,
        desconto: 0,
        valorTotal: 0,
        pagador: '',
        referencia: '',
        historicoLancamentoId: '',
        tipoConta: '',
        contaId: '',
      }),
    },
  },
  components: {
    Modal,
    Dados,
  },
  data() {
    return {
      refs: [],
      formulario: this.form,
    };
  },
  computed: {
    titulo() {
      return this.form.id
        ? this.$t('GERAL.EDITAR')
        : this.$t('GERAL.CADASTRAR');
    },
  },
  watch: {
    'formulario.valorDocumento': 'calcularTotal',
    'formulario.juros': 'calcularTotal',
    'formulario.desconto': 'calcularTotal',
  },
  methods: {
    // FUNÇÕES CRUD:
    salvar() {
      !this.formulario.id ? this.criar() : this.editar();
    },
    criar() {
      if (!this.validarFormulario()) return;
      this.$store.dispatch(START_LOADING);
      LancamentoCaixaService.criar(this.formulario)
        .then(({data}) => {
          mensagem.sucesso(
            this.$t('GERAL.SUCESSO'),
            this.$t('FINANCEIRO.CRIAR_FLUXO_CAIXA_SUCESSO')
          );
          this.formulario = {
            tipoLancamento: '',
            dataLancamento: '',
            observacao: '',
            valorDocumento: 0,
            juros: 0,
            desconto: 0,
            valorTotal: 0,
            pagador: '',
            referencia: '',
            historicoLancamentoId: '',
            tipoConta: '',
            contaId: '',
          };
          
          const confirmacao = mensagem.confirmacao(
            null,
            'Deseja realizar a impressão do recibo ?'
          );

          Promise.resolve(confirmacao).then((confirmacao) => {
            if (confirmacao.isConfirmed) {
              this.gerarReciboLancamento(data);
            }
          });

          this.fecharModal();
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.atualizarTabela();
          this.$store.dispatch(STOP_LOADING);
        });
    },

    gerarReciboLancamento(item) {
      this.$store.dispatch(START_LOADING);
      FluxoCaixaService.imprimirRecibo({ lancamentoCaixaId: item.id, tipoDocumento:"LC"})
        .then(({ data }) => {
          if (data.documento) {
            //converte os bytes em array:
            let bytes = pdf.base64ToArrayBuffer(data.documento);
            // converte o array em blob
            let blob = new Blob([bytes], {
              type: 'application/pdf',
            });

            // faz download do PDF:
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = data.nomeArquivo;
            link.click();
          }
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    editar() {
      if (!this.validarFormulario()) return;
      this.$store.dispatch(START_LOADING);
      LancamentoCaixaService.editar(this.formulario)
        .then(() => {
          mensagem.sucesso(
            this.$t('GERAL.SUCESSO'),
            this.$t('FINANCEIRO.EDITAR_FLUXO_CAIXA_SUCESSO')
          );
          this.fecharModal();
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.atualizarTabela();
          this.$store.dispatch(STOP_LOADING);
        });
    },
    // FUNÇÕES DE VALIDAÇÃO FORMULÁRIO:
    capturarRefs(refs) {
      this.refs = refs;
    },

    validarFormulario() {
      return helpers.validarFormulario(this.refs);
    },
    // FUNÇÕES MODAL:
    fecharModal() {
      this.formulario = {
        tipoLancamento: '',
        dataLancamento: '',
        observacao: '',
        valorDocumento: 0,
        juros: 0,
        desconto: 0,
        valorTotal: 0,
        pagador: '',
        referencia: '',
        historicoLancamentoId: '',
        tipoConta: '',
        contaId: '',
      };
      this.$emit('fechar', 'periodoFinanceiro');
    },
    // FUNÇÕES P/ COMPONENTE PAI:
    atualizarTabela() {
      this.$emit('atualiza-tabela');
    },

    calcularTotal() {
      if (isNaN(this.form.valorDocumento)) this.formulario.valorDocumento = 0;
      if (isNaN(this.form.juros)) this.formulario.juros = 0;
      if (isNaN(this.form.desconto)) this.formulario.desconto = 0;

      this.formulario.valorDocumento = Number.parseFloat(this.formulario.valorDocumento)
      this.formulario.juros = Number.parseFloat(this.formulario.juros)
      this.formulario.desconto = Number.parseFloat(this.formulario.desconto)

      this.formulario.valorTotal =
         this.formulario.valorDocumento +
        this.formulario.juros -
        this.formulario.desconto;
    },
  },
};
</script>
